module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ed6f2d","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-B9HBVC6C3J"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true,"id":"GTM-5TQBVHR","gtmAuth":"wd6R4G7HUQJFqPxvfm_whg","gtmPreview":"env-2","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gravitywell website","short_name":"Gravitywell","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","include_favicon":true,"icon":"src/assets/favicons/favicon-wbg-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"509473439da2cb177abfc0c9d3fbb6da"},
    }]
